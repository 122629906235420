<template>
	<v-container fluid fill-height>
		<v-layout align-center justify-center>
			<v-flex xs12 sm8 md6>
				<material-card color="info" title="Удаление учётной записи приложения Atoto">
					<v-card>
						<v-card-text>
							Для удаления учётной записи необходимо написать через чат "Тех. поддержка" в приложении.

							<p>Укажите только, что именно требуется: блокировка учётной записи или полное удаление. При полном удалении будут утеряны все сохраненные данные о просмотрах, избранном и пр.</p>

							Удаление производится в течении трёх дней.
						</v-card-text>
					</v-card>
				</material-card>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>

export default {
	data: () => ({
	}),
	created() {
		document.title = "Удаление учетной записи";
	},
};
</script>
